import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Genders,CreateGender,UpdateGender } from '../Model/GenderModel';
import { GenderService } from '../services/gender.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.css']
})
export class GenderComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  genderData: Array<Genders>= new Array();
  isAdmin : boolean = false;
  showselectedGender: boolean = false;
  public  GenderName: string;
  selectedGender : Genders = new Genders();

  constructor(private genderService: GenderService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("Gender");
    this.isAdmin = true;
    this.genderService.getGenderData().subscribe(data=>{
      this.genderData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  selectedGenderDetail(gender : Genders){
    this.selectedGender = gender;
    this.showselectedGender = true;
  }

  createGender(){

    this.genderService.CreateNewGender(this.GenderName).subscribe((resp)=>{

      this.Toastr.success('Gender Created Successfully ');
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  EditGender(){

    this.genderService.UpdateGender(this.selectedGender.Id,this.selectedGender.Gender ).subscribe(resp=>{
      this.Toastr.success('Gender Updated Successfully ');
      $("#editGenderModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

  }

}
