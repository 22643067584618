import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RHCServiceService } from '../services/RHC-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import {UpdateRHC, RHCModel } from '../Model/RHCModel';
import {filt, DistrictModel } from '../Model/DistrictModel';
import { ExportService} from '../services/export.service';
import { OrderModule } from 'ngx-order-pipe';
declare var $: any;

@Component({
  selector: 'app-RHC',
  templateUrl: './RHC.component.html',
  styleUrls: ['./RHC.component.css']
})
export class RHCComponent implements OnInit {
  tr:boolean=true;
  districtModel : Array<DistrictModel>= new Array();
  ActiveDistrictModel :any
  RHCList : Array<RHCModel>= new Array();
  showselectedRHC: boolean= false;
  selectedRHC : RHCModel = new RHCModel();
  upRHC : UpdateRHC = new UpdateRHC();
  p : boolean = null;
Id:any
  selectedTehsil : number ;
  public RHCName : string;
  public RHCPhone : string;
  public RHCAddress : string;
  public DistrictID: number;
  RHCTehsil : string;
  Type : string;
   hiddendata:Array<filt>= new Array();
  isAdmin : boolean = false;
  @Output() headerTitle = new EventEmitter<string>();

  constructor(private RHCService: RHCServiceService, private genericService: GenericServiceService,  private router: Router,private exportService: ExportService,private Toastr: ToastrService) { }

  ngOnInit() {
    this.showselectedRHC = false;
    this.headerTitle.emit("RHC");
    this.selectedTehsil = 0;
    
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='RHC' || localStorage.getItem("role")=='SuperAdmin'){
      this.isAdmin = true;
      this.districtModel = [];
       this.ActiveDistrictModel = [];
      this.genericService.getAllDistrictData().subscribe(data=>{
   this.districtModel=data
         var ActiveDistrictModel = [];
      this.districtModel.forEach(function (value) {
      if(value.IsActive==true)
      {
       ActiveDistrictModel.push(value);
      }
   },
   this.hiddendata=ActiveDistrictModel

   ); 
 
     },
      error=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
      this.RHCList = [];
      this.RHCService.getAllRHC().subscribe(data=>{
        this.RHCList = data;
      },
      error=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });

    }
  }


  SelectedDistrictForRHCCreation(id: any){
    this.DistrictID = parseInt(id);
  }
  
  showSelectedDistrictRHC (id:any){
    this.RHCList = [];
    this.RHCService.getRHCByDictrict(id).subscribe(data=>{
      this.RHCList = data;  
    },
    error=>{
      console.log(error);
    });
  }


createRHC(){

  console.log(this.RHCName,this.RHCPhone,this.RHCAddress,this.DistrictID);
  this.RHCService.CreateNewRHC(this.RHCName,this.RHCPhone,this.RHCAddress,this.DistrictID).subscribe((resp)=>{
    // Store access token, UserId and Role in local storage
    this.Toastr.success('RHC Created Successfully ');
    $("#createRHCModal").modal("hide");
    this.ngOnInit();
    },
    (error)=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
}

export(reportType: number) {
if(reportType == 3){
    this.exportService.exportExcel(this.RHCList, 'RHCs');
  }
}

ReverseOrderOfRHC(){
  // reverse the order of allied provider model
  this.RHCList.sort((a, b) => (a.ReferedPatients < b.ReferedPatients) ? 1 : -1 )
  
}

selectedRHCDetail(RHC : any,){
  this.selectedRHC = RHC;
  console.log("data", this.selectedRHC);
  
  this.showselectedRHC = true;
}

UpdateSelectedDistricForRHCCreation(data){
  this.selectedRHC.DistrictId = parseInt(data);
  console.log(this.selectedRHC.DistrictId)
}
// selectnameofrhc(item){
//   this.upRHC=item.Name
//   console.log(item)
// }
EditRHC(){
      this.upRHC.RHCId = this.selectedRHC.Id;
      this.upRHC.Name = this.selectedRHC.Name;
      this.upRHC.Contact = this.selectedRHC.Contact;
      this.upRHC.DistrictId = this.selectedRHC.DistrictId;
      this.upRHC.IsActive = this.selectedRHC.IsActive;
      console.log(this.upRHC)
    this.RHCService.UpdateRHC(this.upRHC).subscribe(resp=>{
      this.Toastr.success('RHC Updated Successfully ');
      $("#detailRHCModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

  }
  openEditingField(){
    this.tr=!this.tr
  }

 
}
