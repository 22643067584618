import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DhqdepartmentsService {
  constructor(private _http:HttpClient,private router:Router) { }


  AllDHQDepartments():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/DHQ/GetDHQDepartments1";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  AddNewDHQDepartment(Name:string, Code:string,IsActive:any):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/CreateDHQDepartment";
    let data = {DepartmentName:Name, TypeCode: Code,IsActive: IsActive}
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateDHQDepartment(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/UpdateDHQDepartment";
    return this._http.post(FullUrl,data,requestOptions);
  }
}
