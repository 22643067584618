
export class UpdateDHQModel {
    
    DHQId : number;
    Name: string;
    Contact : string;
    DistrictId : number;
    Address: string;
    ContactNumber: string;
    IsActive:boolean;
    
}