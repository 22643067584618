import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {

  message:string;
  isAdmin : boolean = false;
  RegionalCoordinator : boolean = false;
  ProjectManager: boolean = false;
  @Output() headerTitle = new EventEmitter<string>();

  constructor(private genericService: GenericServiceService) { }

  ngOnInit() {
    if(localStorage.getItem("role") != null && (localStorage.getItem("role")=='RHC')){
      this.isAdmin = true;
     }
     else{
      this.isAdmin = true;
     }
  }

  receiveHeaderTitle(componentReference) {
    //Below will subscribe to the searchItem emitter
    componentReference.headerTitle.subscribe((data) => {
      // Will receive the data from child here 
     
    this.message = data;
  });
  }

  logout(){
    this.genericService.Logout();
  }

}
