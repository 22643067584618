import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PatientStatusService {

//  baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }

  getPatientStatusData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/GetPatientStatus";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  CreateNewPatientStatus(PatientStatusname:string):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/AddNewPatientStatus";
    let data = {Name:PatientStatusname}
    return this._http.post(FullUrl,data,requestOptions);
  }
  

  UpdatePatientStatus(Id: number,Name: string,IsActive:any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/UpdatePateintStatusName";
    let data = {StatusId:Id,StatusName:Name,IsActive:IsActive}
    return this._http.post(FullUrl,data,requestOptions);
  }
}
