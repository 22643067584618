import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RHCComponent } from './RHC/RHC.component';
import { DHQComponent } from './DHQ/DHQ.component';
import { NCDFocalComponent } from './NCD-Focal/NCD-Focal.component';
import { LoginComponent } from './login/login.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthGuard} from './auth/auth.guard';
import { AllpatientsComponent } from './allpatients/allpatients.component';
import { GenderComponent } from './gender/gender.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { PatientStatusComponent } from './patient-status/patient-status.component';
import { AppointmentStatusComponent } from './appointment-status/appointment-status.component';
import { DistrictsComponent } from './districts/districts.component';
import { FieldOfficerComponent } from './field-officer/field-officer.component';
import { DhqdepartmentsComponent } from './dhqdepartments/dhqdepartments.component';
import { ResponsesComponent } from './responses/responses.component';

const routes: Routes = [
  {
    path:'',redirectTo: 'login', pathMatch: 'full'
  },
  {
    // path: 'dashboard', 
    //     component: AppLayoutComponent,
    //     children: [
    //       { path: '', component: DashboardComponent, pathMatch: 'full', canActivate:[AuthGuard]}
    //     ]
    path: 'dashboard', 
        component: AppLayoutComponent,
        children: [
          { path: '', component: DashboardComponent, pathMatch: 'full' , canActivate:[AuthGuard]}
        ]
  },
{
  path: 'RHC',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: RHCComponent, pathMatch: 'full', canActivate:[AuthGuard]
    }
  ]
},
{
  path: 'DHQ',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DHQComponent, pathMatch: 'full', canActivate:[AuthGuard]
    }
  ]
},
{
  path: 'Focal-Person',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: NCDFocalComponent, pathMatch: 'full', canActivate:[AuthGuard]
    }
  ]
},
{
  path: 'Field-Officer',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: FieldOfficerComponent, pathMatch: 'full', canActivate:[AuthGuard]
    }
  ]
},
{
  path: 'allpatients',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: AllpatientsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'gender',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: GenderComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'diagnosis',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DiagnosisComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'patient-status',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: PatientStatusComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'appointment-status',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: AppointmentStatusComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Districts',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DistrictsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'DHQ-Departments',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DhqdepartmentsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Responses',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: ResponsesComponent, pathMatch: 'full'
    }
  ]
},
{ 
  path: 'login', 
  component: LoginComponent
},
 { path: '**', redirectTo: 'dashboard' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
