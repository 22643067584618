export class NCDDetailModel{
    
    Id: number;
    FocalPersonId:number;
    Name:string;
    Contact:string;
    DistrictId:number;
    IsActive:boolean;
    UserName:any
    Password:any
}