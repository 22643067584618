import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DistrictsService {
  constructor(private _http:HttpClient,private router:Router) { }


  getAllDistrictData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Admin/GetAllDistricts";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  AddNewDistrict(data:any):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/AddNewDistrict";
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateDistrict(DistrictData):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/UpdateDistrict";
    let data ={ Id:DistrictData.Id, DistrictName:DistrictData.DistrictName, IsActive: DistrictData.IsActive,DistrictCode:DistrictData.Code}
    return this._http.post(FullUrl,data,requestOptions);
  }
}
