export class RHCModel{
    Id : number;
    RHCId:number
    Name: any;
    Type: any;
    TehsilName:string;
    Contact : string;
    Address : string;
    DistrictId : number;
    DistrictName:string;
    ReferedPatients:number=0;
    UserName : string;
    Password : string;
    IsActive:boolean;
    Phone:any
}
export class Tehsil{
    Id: number;
    TehsilName:string; 
}
 export class UpdateRHC{
    RHCId: number;
     IsActive:boolean; 
Name :any;
Contact :any;
Address:any;
DistrictId: number;
 }