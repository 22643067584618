import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RHCComponent } from './RHC/RHC.component';
import { DHQComponent } from './DHQ/DHQ.component';
import { NCDFocalComponent } from './NCD-Focal/NCD-Focal.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard} from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AllpatientsComponent } from './allpatients/allpatients.component';
import {NgxPaginationModule} from 'ngx-pagination';  
import { Ng2SearchPipeModule } from 'ng2-search-filter'; 
import { TextMaskModule } from 'angular2-text-mask';
import { OrderModule } from 'ngx-order-pipe';
import { GenderComponent } from './gender/gender.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { PatientStatusComponent } from './patient-status/patient-status.component';
import { AppointmentStatusComponent } from './appointment-status/appointment-status.component';
import { DistrictsComponent } from './districts/districts.component';
import { FieldOfficerComponent } from './field-officer/field-officer.component';
import { DhqdepartmentsComponent } from './dhqdepartments/dhqdepartments.component';
import { ResponsesComponent } from './responses/responses.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    RHCComponent,
    DHQComponent,
    NCDFocalComponent,
    AppLayoutComponent,
    LoginComponent,
    AllpatientsComponent,
    GenderComponent,
    DiagnosisComponent,
    PatientStatusComponent,
    AppointmentStatusComponent,
    DistrictsComponent,
    FieldOfficerComponent,
    DhqdepartmentsComponent,
    ResponsesComponent
  ],
  imports: [
    BrowserModule,
    MultiSelectAllModule,
    TextMaskModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ChartsModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      progressBar: true,
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule
  ],
  providers: [AuthGuard,DatePipe
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: AuthInterceptor,
  //     multi: true
  // }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
