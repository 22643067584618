export class AdminNCDModel {
    Id:number;
    FocalPersonId:number;
    Name:string;
    Contact:string;
    DistrictId:number;
    isFP: boolean = true;
    IsActive:boolean;

}


export class UpdateFieldOfficerModel {
    Id:number;
    FocalPersonId:number;
    Name:string;
    Contact:string;
    DistrictId:number;
    isFP: boolean = false;
    IsActive:boolean;

}