import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';
import { CreateNCDModel } from '../Model/CreateNCDModel';
import { AdminNCDModel } from '../Model/AdminNCDModel';

@Injectable({
  providedIn: 'root'
})
export class NCDServiceService {
//  baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }


  getallFocalPerson():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/FPandFO/GetAllFPsOrFOs?isFP="+true;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  createFocalPerson(NCDInfo: CreateNCDModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/FPandFO/CreateFPorFO";
    //let data = {ApName:ApName, Phone:Phone,Type:Type,Locality :Locality}
    return this._http.post(FullUrl,NCDInfo,requestOptions);
  }


 UpdateFocalPerson(updateFocal: AdminNCDModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/FPandFO/UpdateFPorFO";
    
    return this._http.post(FullUrl,updateFocal,requestOptions);
  }

}
