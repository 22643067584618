export class DHQ{
  
  DHQId : number;
  DHQName: string;
  Contact : string;
  DistrictId : number;
  Address: string;
  Phone : string;
  UserName : string;
  Password : string;
   Referrals:[
     {
      Diagnosis:any
     }
   ]
  DHQUsers: [
    {
      //  "DHQUserId":56,
      //  "DHQLoginId":"d19a2835-74f5-466f-85d7-33b67cf26983",
      //  "Name":"Eye",
      //  "UserName":"EYE-DHQ-RWP-024",
      //  "Password":"bpbi",
      //  "UserType":"Eye",
      colorr:string; 
      ReferredPatients:any,
       DuePatients:any,
       NCPPatients:any,
       NonAttendedPatients:any,
       AttendedPatients:any,
      //  IsDhqUserActive:true,
    }
  ]
  DHQUserId: number;
  Name: string;
  IsActive:boolean;
  ScheduledDays: [
    {
    ScheduledDayId : any;
    IsScheduled: any;
    }
  ];
  
}

export class DHQ1{

  PatientName:any
  Father_Husband_Name:any
  CNIC :any
  LastStatusUpdatedAt:any
  DOB:any
  NCDRegistrationNumber:any
  Address:any
  Contact:any
  Gender:any
  Status:any
  DHQName:any
  Referrals:[
    {
     Diagnosis:any
    }
  ]
}

