export class Genders{
    Id :number;
    FirstName :string;	
    LastName :string;	
    DistrictName :string;
    PositivePatients : number;
    NCP : number;
    Gender:string;
    GenderId:string;
}

export class UpdateGender{
    Id: number;
    FirstName :string;	
    LastName :string;	
    Phone :string;
 
}

export class CreateGender{
    Id: number;
    FirstName :string;	
    LastName :string;	
    Phone :string;
 
}