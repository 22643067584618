export class DistrictModel{
    DistrictId : any;
    IsActive : any;
    DistrictName : any;
    DistrictCode : any;
}
export class edit{
    DistrictId : any;
    IsActive : any;
    DistrictName : any;
    DistrictCode : any;
    Code:any
}
export class filt{
    DistrictId : any;
    IsActive : any;
    DistrictName : any;
    DistrictCode : any;
}